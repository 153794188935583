<template lang="html">
  <v-container class="fill-height">
    <v-row justify="center" align="center">
      <v-col cols="4" class="d-flex flex-column align-center">
        <v-icon color="grey lighten-1" class="animate__animated animate__pulse animate__repeat-3" size="100">mdi-hammer-wrench</v-icon>
        <span class="display-1 font-weight-light">Sedang dalam pengembangan.</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import 'animate.css'
export default {
}
</script>

<style lang="css" scoped>
</style>
